/* ../src/components/Nav/Nav.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	null_to_empty,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faHome, faAdjust } from '@fortawesome/free-solid-svg-icons';
import { FluidContainer, TopBar } from '@makinox/makinox-ui';
import { FontAwesomeIcon } from 'fontawesome-svelte';
import { css } from '@stitches/core';
import Logo from '../../assets/Logo.svelte';
import { isDark } from '../../data/store';

function create_fragment(ctx) {
	let header;
	let nav;
	let div0;
	let span0;
	let logo;
	let t0;
	let span1;
	let t2;
	let span2;
	let fontawesomeicon0;
	let span2_class_value;
	let t3;
	let div1;
	let a0;
	let fontawesomeicon1;
	let a0_class_value;
	let t4;
	let a1;
	let fontawesomeicon2;
	let a1_class_value;
	let t5;
	let a2;
	let fontawesomeicon3;
	let a2_class_value;
	let nav_class_value;
	let header_class_value;
	let current;
	let mounted;
	let dispose;
	logo = new Logo({});
	fontawesomeicon0 = new FontAwesomeIcon({ props: { icon: faAdjust } });
	fontawesomeicon1 = new FontAwesomeIcon({ props: { icon: faHome } });
	fontawesomeicon2 = new FontAwesomeIcon({ props: { icon: faTwitter } });
	fontawesomeicon3 = new FontAwesomeIcon({ props: { icon: faLinkedin } });

	return {
		c() {
			header = element("header");
			nav = element("nav");
			div0 = element("div");
			span0 = element("span");
			create_component(logo.$$.fragment);
			t0 = space();
			span1 = element("span");
			span1.textContent = "oomes";
			t2 = space();
			span2 = element("span");
			create_component(fontawesomeicon0.$$.fragment);
			t3 = space();
			div1 = element("div");
			a0 = element("a");
			create_component(fontawesomeicon1.$$.fragment);
			t4 = space();
			a1 = element("a");
			create_component(fontawesomeicon2.$$.fragment);
			t5 = space();
			a2 = element("a");
			create_component(fontawesomeicon3.$$.fragment);
			attr(span0, "class", "svelte-pre7qq");
			attr(span1, "class", "svelte-pre7qq");
			attr(span2, "class", span2_class_value = "" + (null_to_empty(/*linkTheme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] })) + " svelte-pre7qq"));
			attr(div0, "class", "svelte-pre7qq");
			attr(a0, "class", a0_class_value = /*linkTheme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] }));
			attr(a0, "target", "_blank");
			attr(a0, "rel", "noopener noreferrer");
			attr(a0, "href", "https://jesusbossa.dev");
			attr(a1, "class", a1_class_value = /*linkTheme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] }));
			attr(a1, "target", "_blank");
			attr(a1, "rel", "noopener noreferrer");
			attr(a1, "href", "https://twitter.com/jesMakinox");
			attr(a2, "class", a2_class_value = /*linkTheme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] }));
			attr(a2, "target", "_blank");
			attr(a2, "rel", "noopener noreferrer");
			attr(a2, "href", "https://www.linkedin.com/in/makinox/");
			attr(nav, "class", nav_class_value = "" + (null_to_empty(`flex justify-between ${TopBar({ isDark: /*$isDark*/ ctx[0] })} ${/*sectionTheme*/ ctx[2]({ isDark: /*$isDark*/ ctx[0] })}`) + " svelte-pre7qq"));
			attr(header, "class", header_class_value = "" + (null_to_empty(FluidContainer()) + " svelte-pre7qq"));
		},
		m(target, anchor) {
			insert(target, header, anchor);
			append(header, nav);
			append(nav, div0);
			append(div0, span0);
			mount_component(logo, span0, null);
			append(div0, t0);
			append(div0, span1);
			append(div0, t2);
			append(div0, span2);
			mount_component(fontawesomeicon0, span2, null);
			append(nav, t3);
			append(nav, div1);
			append(div1, a0);
			mount_component(fontawesomeicon1, a0, null);
			append(div1, t4);
			append(div1, a1);
			mount_component(fontawesomeicon2, a1, null);
			append(div1, t5);
			append(div1, a2);
			mount_component(fontawesomeicon3, a2, null);
			current = true;

			if (!mounted) {
				dispose = listen(span2, "click", isDark.toggle);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*$isDark*/ 1 && span2_class_value !== (span2_class_value = "" + (null_to_empty(/*linkTheme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] })) + " svelte-pre7qq"))) {
				attr(span2, "class", span2_class_value);
			}

			if (!current || dirty & /*$isDark*/ 1 && a0_class_value !== (a0_class_value = /*linkTheme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] }))) {
				attr(a0, "class", a0_class_value);
			}

			if (!current || dirty & /*$isDark*/ 1 && a1_class_value !== (a1_class_value = /*linkTheme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] }))) {
				attr(a1, "class", a1_class_value);
			}

			if (!current || dirty & /*$isDark*/ 1 && a2_class_value !== (a2_class_value = /*linkTheme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] }))) {
				attr(a2, "class", a2_class_value);
			}

			if (!current || dirty & /*$isDark*/ 1 && nav_class_value !== (nav_class_value = "" + (null_to_empty(`flex justify-between ${TopBar({ isDark: /*$isDark*/ ctx[0] })} ${/*sectionTheme*/ ctx[2]({ isDark: /*$isDark*/ ctx[0] })}`) + " svelte-pre7qq"))) {
				attr(nav, "class", nav_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(logo.$$.fragment, local);
			transition_in(fontawesomeicon0.$$.fragment, local);
			transition_in(fontawesomeicon1.$$.fragment, local);
			transition_in(fontawesomeicon2.$$.fragment, local);
			transition_in(fontawesomeicon3.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(logo.$$.fragment, local);
			transition_out(fontawesomeicon0.$$.fragment, local);
			transition_out(fontawesomeicon1.$$.fragment, local);
			transition_out(fontawesomeicon2.$$.fragment, local);
			transition_out(fontawesomeicon3.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(header);
			destroy_component(logo);
			destroy_component(fontawesomeicon0);
			destroy_component(fontawesomeicon1);
			destroy_component(fontawesomeicon2);
			destroy_component(fontawesomeicon3);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $isDark;
	component_subscribe($$self, isDark, $$value => $$invalidate(0, $isDark = $$value));

	const linkTheme = css({
		cursor: 'pointer',
		padding: '0 1px',
		variants: {
			isDark: {
				true: { color: 'rgb(var(--dark-primary))' },
				false: { color: 'rgb(var(--light-primary))' }
			}
		}
	});

	const sectionTheme = css({
		variants: {
			isDark: {
				true: {
					backgroundColor: 'rgba(var(--dark-background), 0.8) !important'
				},
				false: {
					backgroundColor: 'rgba(var(--light-background), 0.4) !important'
				}
			}
		}
	});

	return [$isDark, linkTheme, sectionTheme];
}

class Nav extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Nav;