/* ../src/App.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import './css/colors.css';
import '@makinox/makinox-ui/dist/index.css';
import ListContainer from './components/ListContainer/ListContainer.svelte';
import ThemeManager from './components/ThemeManager/ThemeManager.svelte';
import Hero from './components/Hero/Hero.svelte';
import Nav from './components/Nav/Nav.svelte';

function create_default_slot(ctx) {
	let nav;
	let t0;
	let hero;
	let t1;
	let listcontainer;
	let current;
	nav = new Nav({});
	hero = new Hero({});
	listcontainer = new ListContainer({});

	return {
		c() {
			create_component(nav.$$.fragment);
			t0 = space();
			create_component(hero.$$.fragment);
			t1 = space();
			create_component(listcontainer.$$.fragment);
		},
		m(target, anchor) {
			mount_component(nav, target, anchor);
			insert(target, t0, anchor);
			mount_component(hero, target, anchor);
			insert(target, t1, anchor);
			mount_component(listcontainer, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(nav.$$.fragment, local);
			transition_in(hero.$$.fragment, local);
			transition_in(listcontainer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(nav.$$.fragment, local);
			transition_out(hero.$$.fragment, local);
			transition_out(listcontainer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(nav, detaching);
			if (detaching) detach(t0);
			destroy_component(hero, detaching);
			if (detaching) detach(t1);
			destroy_component(listcontainer, detaching);
		}
	};
}

function create_fragment(ctx) {
	let thememanager;
	let current;

	thememanager = new ThemeManager({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(thememanager.$$.fragment);
		},
		m(target, anchor) {
			mount_component(thememanager, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const thememanager_changes = {};

			if (dirty & /*$$scope*/ 1) {
				thememanager_changes.$$scope = { dirty, ctx };
			}

			thememanager.$set(thememanager_changes);
		},
		i(local) {
			if (current) return;
			transition_in(thememanager.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(thememanager.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(thememanager, detaching);
		}
	};
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default App;