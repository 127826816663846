/* ../src/components/Hero/Hero.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

import { FluidContainer } from '@makinox/makinox-ui';
import Gradient from '../../assets/gradient.svelte';

function create_fragment(ctx) {
	let section;
	let gradient;
	let section_class_value;
	let current;
	gradient = new Gradient({ props: { class: "hero-gradient" } });

	return {
		c() {
			section = element("section");
			create_component(gradient.$$.fragment);
			attr(section, "class", section_class_value = FluidContainer());
		},
		m(target, anchor) {
			insert(target, section, anchor);
			mount_component(gradient, section, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(gradient.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(gradient.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			destroy_component(gradient);
		}
	};
}

class Hero extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Hero;