/* ../src/assets/Logo.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style,
	svg_element,
	text
} from "svelte/internal";

function create_fragment(ctx) {
	let svg;
	let defs;
	let linearGradient0;
	let stop0;
	let stop1;
	let stop2;
	let linearGradient1;
	let stop3;
	let stop4;
	let filter0;
	let feMorphology;
	let filter1;
	let feFlood;
	let feConvolveMatrix;
	let feOffset0;
	let feComposite0;
	let feGaussianBlur0;
	let feColorMatrix0;
	let feOffset1;
	let feOffset2;
	let feComposite1;
	let feGaussianBlur1;
	let feColorMatrix1;
	let feComposite2;
	let feOffset3;
	let feMerge;
	let feMergeNode0;
	let feMergeNode1;
	let feMergeNode2;
	let feMergeNode3;
	let g1;
	let g0;
	let path0;
	let g3;
	let g2;
	let path1;
	let style;
	let t;

	return {
		c() {
			svg = svg_element("svg");
			defs = svg_element("defs");
			linearGradient0 = svg_element("linearGradient");
			stop0 = svg_element("stop");
			stop1 = svg_element("stop");
			stop2 = svg_element("stop");
			linearGradient1 = svg_element("linearGradient");
			stop3 = svg_element("stop");
			stop4 = svg_element("stop");
			filter0 = svg_element("filter");
			feMorphology = svg_element("feMorphology");
			filter1 = svg_element("filter");
			feFlood = svg_element("feFlood");
			feConvolveMatrix = svg_element("feConvolveMatrix");
			feOffset0 = svg_element("feOffset");
			feComposite0 = svg_element("feComposite");
			feGaussianBlur0 = svg_element("feGaussianBlur");
			feColorMatrix0 = svg_element("feColorMatrix");
			feOffset1 = svg_element("feOffset");
			feOffset2 = svg_element("feOffset");
			feComposite1 = svg_element("feComposite");
			feGaussianBlur1 = svg_element("feGaussianBlur");
			feColorMatrix1 = svg_element("feColorMatrix");
			feComposite2 = svg_element("feComposite");
			feOffset3 = svg_element("feOffset");
			feMerge = svg_element("feMerge");
			feMergeNode0 = svg_element("feMergeNode");
			feMergeNode1 = svg_element("feMergeNode");
			feMergeNode2 = svg_element("feMergeNode");
			feMergeNode3 = svg_element("feMergeNode");
			g1 = svg_element("g");
			g0 = svg_element("g");
			path0 = svg_element("path");
			g3 = svg_element("g");
			g2 = svg_element("g");
			path1 = svg_element("path");
			style = svg_element("style");
			t = text("text {\n      font-size: 64px;\n      font-family: Arial Black;\n      dominant-baseline: central;\n      text-anchor: middle;\n    }");
			attr(stop0, "offset", "0.0");
			attr(stop0, "stop-color", "#ffb200");
			attr(stop1, "offset", "0.5");
			attr(stop1, "stop-color", "#e10057");
			attr(stop2, "offset", "1.0");
			attr(stop2, "stop-color", "#5A1A80");
			attr(linearGradient0, "id", "editing-shiny-gradient");
			attr(linearGradient0, "x1", "0.04322727117869957");
			attr(linearGradient0, "x2", "0.9567727288213004");
			attr(linearGradient0, "y1", "0.2966316784620999");
			attr(linearGradient0, "y2", "0.7033683215379001");
			attr(linearGradient0, "gradientUnits", "objectBoundingBox");
			attr(stop3, "offset", "0.2");
			attr(stop3, "stop-color", "#fff");
			attr(stop3, "stop-opacity", "0.8");
			attr(stop4, "offset", "0.8");
			attr(stop4, "stop-color", "#fff");
			attr(stop4, "stop-opacity", "0");
			attr(linearGradient1, "id", "editing-shiny-gradient2");
			attr(linearGradient1, "x1", "0");
			attr(linearGradient1, "x2", "0");
			attr(linearGradient1, "y1", "0");
			attr(linearGradient1, "y2", "1");
			attr(feMorphology, "operator", "erode");
			attr(feMorphology, "radius", "2");
			attr(filter0, "id", "editing-shiny2");
			attr(filter0, "x", "-100%");
			attr(filter0, "y", "-100%");
			attr(filter0, "width", "300%");
			attr(filter0, "height", "300%");
			attr(feFlood, "flood-color", "#fff");
			attr(feFlood, "result", "flood");
			attr(feConvolveMatrix, "in", "SourceGraphic");
			attr(feConvolveMatrix, "result", "conv");
			attr(feConvolveMatrix, "order", "8,8");
			attr(feConvolveMatrix, "divisor", "1");
			attr(feConvolveMatrix, "kernelMatrix", "0 0 0 0 0 0 0 0 0 0 0 1 1 0 0 0 0 0 0 1 1 0 0 0 0 0 1 0 0 1 0 0 0 0 1 0 0 1 0 0 0 1 0 0 0 0 1 0 0 1 1 0 0 1 1 0 0 0 0 1 1 0 0 0 ");
			attr(feOffset0, "dx", "0");
			attr(feOffset0, "dy", "4");
			attr(feOffset0, "in", "conv");
			attr(feOffset0, "result", "offset");
			attr(feComposite0, "operator", "in");
			attr(feComposite0, "in", "flood");
			attr(feComposite0, "in2", "offset");
			attr(feComposite0, "result", "comp");
			attr(feGaussianBlur0, "in", "offset");
			attr(feGaussianBlur0, "stdDeviation", "3");
			attr(feGaussianBlur0, "result", "shadow");
			attr(feColorMatrix0, "in", "shadow");
			attr(feColorMatrix0, "type", "matrix");
			attr(feColorMatrix0, "values", "0.7 0 0 0 0  0 0.7 0 0 0  0 0 0.7 0 0  0 0 0 0.3 0");
			attr(feColorMatrix0, "result", "dark-shadow");
			attr(feOffset1, "dx", "0");
			attr(feOffset1, "dy", "4");
			attr(feOffset1, "in", "dark-shadow");
			attr(feOffset1, "result", "offset-shadow");
			attr(feOffset2, "dx", "0");
			attr(feOffset2, "dy", "2");
			attr(feOffset2, "in", "conv");
			attr(feOffset2, "result", "offset-2");
			attr(feComposite1, "operator", "out");
			attr(feComposite1, "in", "offset");
			attr(feComposite1, "in2", "offset-2");
			attr(feComposite1, "result", "edge-diff");
			attr(feGaussianBlur1, "in", "edge-diff");
			attr(feGaussianBlur1, "stdDeviation", "1");
			attr(feGaussianBlur1, "result", "edge-blur");
			attr(feColorMatrix1, "in", "edge-blur");
			attr(feColorMatrix1, "result", "edge-shadow");
			attr(feColorMatrix1, "type", "matrix");
			attr(feColorMatrix1, "values", "0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 0.5 0");
			attr(feComposite2, "operator", "in");
			attr(feComposite2, "in", "edge-shadow");
			attr(feComposite2, "in2", "offset");
			attr(feComposite2, "result", "edge-shadow-in");
			attr(feOffset3, "dx", "0");
			attr(feOffset3, "dy", "1");
			attr(feOffset3, "in", "edge-shadow-in");
			attr(feOffset3, "result", "edge-shadow-final");
			attr(feMergeNode0, "in", "offset-shadow");
			attr(feMergeNode1, "in", "comp");
			attr(feMergeNode2, "in", "edge-shadow-final");
			attr(feMergeNode3, "in", "SourceGraphic");
			attr(feMerge, "result", "out");
			attr(filter1, "id", "editing-shiny");
			attr(filter1, "x", "-100%");
			attr(filter1, "y", "-100%");
			attr(filter1, "width", "300%");
			attr(filter1, "height", "300%");
			attr(path0, "d", "M14.21 0L0.51 0L8.32-42.05L8.32-42.05Q14.66-42.75 23.90-42.75L23.90-42.75L23.90-42.75Q33.15-42.75 37.44-40.06L37.44-40.06L37.44-40.06Q41.73-37.38 41.73-31.97L41.73-31.97L41.73-31.97Q41.73-26.56 38.94-23.17L38.94-23.17L38.94-23.17Q36.16-19.78 31.36-18.69L31.36-18.69L31.36-18.69Q32.45-13.38 34.94-9.09L34.94-9.09L34.94-9.09Q37.25-5.06 39.94-3.78L39.94-3.78L39.94-3.78Q38.98-1.09 37.02 0.10L37.02 0.10L37.02 0.10Q35.07 1.28 32.29 1.28L32.29 1.28L32.29 1.28Q29.50 1.28 27.33-0.35L27.33-0.35L27.33-0.35Q25.15-1.98 23.42-4.99L23.42-4.99L23.42-4.99Q19.78-11.52 19.39-21.82L19.39-21.82L19.84-21.82L19.84-21.82Q24.58-21.95 26.94-24.42L26.94-24.42L26.94-24.42Q29.31-26.88 29.31-32.06L29.31-32.06L29.31-32.06Q29.31-38.53 23.87-38.78L23.87-38.78L22.46-38.78L22.46-38.78Q21.89-38.78 21.57-38.72L21.57-38.72L14.21 0Z");
			attr(path0, "fill", "url(#editing-shiny-gradient)");
			attr(g0, "transform", "translate(228.87999939918518, 97.01499938964844)");
			attr(g1, "filter", "url(#editing-shiny)");
			attr(path1, "d", "M14.21 0L0.51 0L8.32-42.05L8.32-42.05Q14.66-42.75 23.90-42.75L23.90-42.75L23.90-42.75Q33.15-42.75 37.44-40.06L37.44-40.06L37.44-40.06Q41.73-37.38 41.73-31.97L41.73-31.97L41.73-31.97Q41.73-26.56 38.94-23.17L38.94-23.17L38.94-23.17Q36.16-19.78 31.36-18.69L31.36-18.69L31.36-18.69Q32.45-13.38 34.94-9.09L34.94-9.09L34.94-9.09Q37.25-5.06 39.94-3.78L39.94-3.78L39.94-3.78Q38.98-1.09 37.02 0.10L37.02 0.10L37.02 0.10Q35.07 1.28 32.29 1.28L32.29 1.28L32.29 1.28Q29.50 1.28 27.33-0.35L27.33-0.35L27.33-0.35Q25.15-1.98 23.42-4.99L23.42-4.99L23.42-4.99Q19.78-11.52 19.39-21.82L19.39-21.82L19.84-21.82L19.84-21.82Q24.58-21.95 26.94-24.42L26.94-24.42L26.94-24.42Q29.31-26.88 29.31-32.06L29.31-32.06L29.31-32.06Q29.31-38.53 23.87-38.78L23.87-38.78L22.46-38.78L22.46-38.78Q21.89-38.78 21.57-38.72L21.57-38.72L14.21 0Z");
			attr(path1, "fill", "url(#editing-shiny-gradient2)");
			attr(g2, "transform", "translate(228.87999939918518, 97.01499938964844)");
			attr(g3, "filter", "url(#editing-shiny2)");
			attr(svg, "width", "20px");
			attr(svg, "height", "20px");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "viewBox", "210.35625 14.297509765625001 60 80");
			set_style(svg, "background", "rgba(0, 0, 0, 0)");
			attr(svg, "preserveAspectRatio", "xMidYMid");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, defs);
			append(defs, linearGradient0);
			append(linearGradient0, stop0);
			append(linearGradient0, stop1);
			append(linearGradient0, stop2);
			append(defs, linearGradient1);
			append(linearGradient1, stop3);
			append(linearGradient1, stop4);
			append(defs, filter0);
			append(filter0, feMorphology);
			append(defs, filter1);
			append(filter1, feFlood);
			append(filter1, feConvolveMatrix);
			append(filter1, feOffset0);
			append(filter1, feComposite0);
			append(filter1, feGaussianBlur0);
			append(filter1, feColorMatrix0);
			append(filter1, feOffset1);
			append(filter1, feOffset2);
			append(filter1, feComposite1);
			append(filter1, feGaussianBlur1);
			append(filter1, feColorMatrix1);
			append(filter1, feComposite2);
			append(filter1, feOffset3);
			append(filter1, feMerge);
			append(feMerge, feMergeNode0);
			append(feMerge, feMergeNode1);
			append(feMerge, feMergeNode2);
			append(feMerge, feMergeNode3);
			append(svg, g1);
			append(g1, g0);
			append(g0, path0);
			append(svg, g3);
			append(g3, g2);
			append(g2, path1);
			append(svg, style);
			append(style, t);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

class Logo extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Logo;