/* ../src/assets/gradient.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	detach,
	exclude_internal_props,
	init,
	insert,
	noop,
	safe_not_equal,
	svg_element
} from "svelte/internal";

import { getRandomNumber } from '../utils/getRandomNumber';
import { isDark } from '../data/store';

function create_else_block(ctx) {
	let circle0;
	let circle1;
	let circle2;
	let circle3;
	let circle4;
	let circle5;

	return {
		c() {
			circle0 = svg_element("circle");
			circle1 = svg_element("circle");
			circle2 = svg_element("circle");
			circle3 = svg_element("circle");
			circle4 = svg_element("circle");
			circle5 = svg_element("circle");
			attr(circle0, "cx", "918");
			attr(circle0, "cy", "268");
			attr(circle0, "fill", /*colorFill*/ ctx[1]);
			attr(circle0, "r", "363");
			attr(circle1, "cx", "439");
			attr(circle1, "cy", "43");
			attr(circle1, "fill", /*colorBack*/ ctx[0]);
			attr(circle1, "r", "363");
			attr(circle2, "cx", "717");
			attr(circle2, "cy", "152");
			attr(circle2, "fill", /*colorFill*/ ctx[1]);
			attr(circle2, "r", "363");
			attr(circle3, "cx", "151");
			attr(circle3, "cy", "96");
			attr(circle3, "fill", /*colorFill*/ ctx[1]);
			attr(circle3, "r", "363");
			attr(circle4, "cx", "380");
			attr(circle4, "cy", "324");
			attr(circle4, "fill", /*colorBack*/ ctx[0]);
			attr(circle4, "r", "363");
			attr(circle5, "cx", "107");
			attr(circle5, "cy", "529");
			attr(circle5, "fill", /*colorFill*/ ctx[1]);
			attr(circle5, "r", "363");
		},
		m(target, anchor) {
			insert(target, circle0, anchor);
			insert(target, circle1, anchor);
			insert(target, circle2, anchor);
			insert(target, circle3, anchor);
			insert(target, circle4, anchor);
			insert(target, circle5, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*colorFill*/ 2) {
				attr(circle0, "fill", /*colorFill*/ ctx[1]);
			}

			if (dirty & /*colorBack*/ 1) {
				attr(circle1, "fill", /*colorBack*/ ctx[0]);
			}

			if (dirty & /*colorFill*/ 2) {
				attr(circle2, "fill", /*colorFill*/ ctx[1]);
			}

			if (dirty & /*colorFill*/ 2) {
				attr(circle3, "fill", /*colorFill*/ ctx[1]);
			}

			if (dirty & /*colorBack*/ 1) {
				attr(circle4, "fill", /*colorBack*/ ctx[0]);
			}

			if (dirty & /*colorFill*/ 2) {
				attr(circle5, "fill", /*colorFill*/ ctx[1]);
			}
		},
		d(detaching) {
			if (detaching) detach(circle0);
			if (detaching) detach(circle1);
			if (detaching) detach(circle2);
			if (detaching) detach(circle3);
			if (detaching) detach(circle4);
			if (detaching) detach(circle5);
		}
	};
}

// (51:27) 
function create_if_block_1(ctx) {
	let circle0;
	let circle1;
	let circle2;
	let circle3;
	let circle4;
	let circle5;

	return {
		c() {
			circle0 = svg_element("circle");
			circle1 = svg_element("circle");
			circle2 = svg_element("circle");
			circle3 = svg_element("circle");
			circle4 = svg_element("circle");
			circle5 = svg_element("circle");
			attr(circle0, "cx", "954");
			attr(circle0, "cy", "496");
			attr(circle0, "fill", /*colorBack*/ ctx[0]);
			attr(circle0, "r", "363");
			attr(circle1, "cx", "733");
			attr(circle1, "cy", "514");
			attr(circle1, "fill", /*colorFill*/ ctx[1]);
			attr(circle1, "r", "363");
			attr(circle2, "cx", "648");
			attr(circle2, "cy", "70");
			attr(circle2, "fill", /*colorBack*/ ctx[0]);
			attr(circle2, "r", "363");
			attr(circle3, "cx", "502");
			attr(circle3, "cy", "537");
			attr(circle3, "fill", /*colorBack*/ ctx[0]);
			attr(circle3, "r", "363");
			attr(circle4, "cx", "269");
			attr(circle4, "cy", "243");
			attr(circle4, "fill", /*colorFill*/ ctx[1]);
			attr(circle4, "r", "363");
			attr(circle5, "cx", "936");
			attr(circle5, "cy", "94");
			attr(circle5, "fill", /*colorBack*/ ctx[0]);
			attr(circle5, "r", "363");
		},
		m(target, anchor) {
			insert(target, circle0, anchor);
			insert(target, circle1, anchor);
			insert(target, circle2, anchor);
			insert(target, circle3, anchor);
			insert(target, circle4, anchor);
			insert(target, circle5, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*colorBack*/ 1) {
				attr(circle0, "fill", /*colorBack*/ ctx[0]);
			}

			if (dirty & /*colorFill*/ 2) {
				attr(circle1, "fill", /*colorFill*/ ctx[1]);
			}

			if (dirty & /*colorBack*/ 1) {
				attr(circle2, "fill", /*colorBack*/ ctx[0]);
			}

			if (dirty & /*colorBack*/ 1) {
				attr(circle3, "fill", /*colorBack*/ ctx[0]);
			}

			if (dirty & /*colorFill*/ 2) {
				attr(circle4, "fill", /*colorFill*/ ctx[1]);
			}

			if (dirty & /*colorBack*/ 1) {
				attr(circle5, "fill", /*colorBack*/ ctx[0]);
			}
		},
		d(detaching) {
			if (detaching) detach(circle0);
			if (detaching) detach(circle1);
			if (detaching) detach(circle2);
			if (detaching) detach(circle3);
			if (detaching) detach(circle4);
			if (detaching) detach(circle5);
		}
	};
}

// (44:4) {#if random === 1}
function create_if_block(ctx) {
	let circle0;
	let circle1;
	let circle2;
	let circle3;
	let circle4;
	let circle5;

	return {
		c() {
			circle0 = svg_element("circle");
			circle1 = svg_element("circle");
			circle2 = svg_element("circle");
			circle3 = svg_element("circle");
			circle4 = svg_element("circle");
			circle5 = svg_element("circle");
			attr(circle0, "cx", "845");
			attr(circle0, "cy", "356");
			attr(circle0, "fill", /*colorFill*/ ctx[1]);
			attr(circle0, "r", "363");
			attr(circle1, "cx", "67");
			attr(circle1, "cy", "321");
			attr(circle1, "fill", /*colorBack*/ ctx[0]);
			attr(circle1, "r", "363");
			attr(circle2, "cx", "277");
			attr(circle2, "cy", "492");
			attr(circle2, "fill", /*colorFill*/ ctx[1]);
			attr(circle2, "r", "363");
			attr(circle3, "cx", "78");
			attr(circle3, "cy", "23");
			attr(circle3, "fill", /*colorFill*/ ctx[1]);
			attr(circle3, "r", "363");
			attr(circle4, "cx", "594");
			attr(circle4, "cy", "490");
			attr(circle4, "fill", /*colorBack*/ ctx[0]);
			attr(circle4, "r", "363");
			attr(circle5, "cx", "651");
			attr(circle5, "cy", "180");
			attr(circle5, "fill", /*colorFill*/ ctx[1]);
			attr(circle5, "r", "363");
		},
		m(target, anchor) {
			insert(target, circle0, anchor);
			insert(target, circle1, anchor);
			insert(target, circle2, anchor);
			insert(target, circle3, anchor);
			insert(target, circle4, anchor);
			insert(target, circle5, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*colorFill*/ 2) {
				attr(circle0, "fill", /*colorFill*/ ctx[1]);
			}

			if (dirty & /*colorBack*/ 1) {
				attr(circle1, "fill", /*colorBack*/ ctx[0]);
			}

			if (dirty & /*colorFill*/ 2) {
				attr(circle2, "fill", /*colorFill*/ ctx[1]);
			}

			if (dirty & /*colorFill*/ 2) {
				attr(circle3, "fill", /*colorFill*/ ctx[1]);
			}

			if (dirty & /*colorBack*/ 1) {
				attr(circle4, "fill", /*colorBack*/ ctx[0]);
			}

			if (dirty & /*colorFill*/ 2) {
				attr(circle5, "fill", /*colorFill*/ ctx[1]);
			}
		},
		d(detaching) {
			if (detaching) detach(circle0);
			if (detaching) detach(circle1);
			if (detaching) detach(circle2);
			if (detaching) detach(circle3);
			if (detaching) detach(circle4);
			if (detaching) detach(circle5);
		}
	};
}

function create_fragment(ctx) {
	let svg;
	let defs;
	let filter;
	let feFlood;
	let feBlend;
	let feGaussianBlur;
	let rect;
	let g;
	let svg_viewBox_value;
	let svg_class_value;

	function select_block_type(ctx, dirty) {
		if (/*random*/ ctx[5] === 1) return create_if_block;
		if (/*random*/ ctx[5] === 2) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			svg = svg_element("svg");
			defs = svg_element("defs");
			filter = svg_element("filter");
			feFlood = svg_element("feFlood");
			feBlend = svg_element("feBlend");
			feGaussianBlur = svg_element("feGaussianBlur");
			rect = svg_element("rect");
			g = svg_element("g");
			if_block.c();
			attr(feFlood, "flood-opacity", "0");
			attr(feFlood, "result", "BackgroundImageFix");
			attr(feBlend, "mode", "normal");
			attr(feBlend, "in", "SourceGraphic");
			attr(feBlend, "in2", "BackgroundImageFix");
			attr(feBlend, "result", "shape");
			attr(feGaussianBlur, "stdDeviation", "163");
			attr(feGaussianBlur, "result", "effect1_foregroundBlur");
			attr(filter, "id", "blur1");
			attr(filter, "x", "-10%");
			attr(filter, "y", "-10%");
			attr(filter, "width", "120%");
			attr(filter, "height", "120%");
			attr(rect, "width", /*canvasWidth*/ ctx[2]);
			attr(rect, "height", /*canvasHeight*/ ctx[3]);
			attr(rect, "fill", /*colorBack*/ ctx[0]);
			attr(g, "filter", "url(#blur1)");
			attr(svg, "id", "visual");

			attr(svg, "viewBox", svg_viewBox_value = `0 0 ${/*isLimited*/ ctx[4]
			? /*canvasHeight*/ ctx[3] + ' ' + /*canvasWidth*/ ctx[2]
			: '1060 570'}`);

			attr(svg, "class", svg_class_value = /*$$props*/ ctx[6].class);
			attr(svg, "width", /*canvasWidth*/ ctx[2]);
			attr(svg, "height", /*canvasHeight*/ ctx[3]);
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr(svg, "xmlns:xlink", "http://www.w3.org/1999/xlink");
			attr(svg, "version", "1.1");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, defs);
			append(defs, filter);
			append(filter, feFlood);
			append(filter, feBlend);
			append(filter, feGaussianBlur);
			append(svg, rect);
			append(svg, g);
			if_block.m(g, null);
		},
		p(ctx, [dirty]) {
			if (dirty & /*colorBack*/ 1) {
				attr(rect, "fill", /*colorBack*/ ctx[0]);
			}

			if_block.p(ctx, dirty);

			if (dirty & /*$$props*/ 64 && svg_class_value !== (svg_class_value = /*$$props*/ ctx[6].class)) {
				attr(svg, "class", svg_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(svg);
			if_block.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let canvasWidth = `1920px`;
	let canvasHeight = `1032px`;
	const isLimited = window.innerWidth < 670;
	const random = getRandomNumber(1, 4);
	let colorBack = 'rgb(var(--light-background))';
	let colorFill = `rgb(var(--light-primary))`;

	isDark.subscribe(value => {
		if (value) {
			$$invalidate(0, colorBack = 'rgb(var(--dark-background))');
			$$invalidate(1, colorFill = `rgb(var(--dark-primary))`);
		} else {
			$$invalidate(0, colorBack = 'rgb(var(--light-background))');
			$$invalidate(1, colorFill = `rgb(var(--light-primary))`);
		}
	});

	$$self.$$set = $$new_props => {
		$$invalidate(6, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
	};

	$$props = exclude_internal_props($$props);
	return [colorBack, colorFill, canvasWidth, canvasHeight, isLimited, random, $$props];
}

class Gradient extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Gradient;