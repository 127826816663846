/* ../src/components/ListContainer/ListContainer.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	null_to_empty,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { FluidContainer } from '@makinox/makinox-ui';
import { css } from '@stitches/core';
import CardArtitcle from '../CardArtitcle/CardArtitcle.svelte';
import { isDark } from '../../data/store';
import dbCards from '../../data/db.json';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[2] = list[i];
	return child_ctx;
}

// (24:2) {#each dbCards as card}
function create_each_block(ctx) {
	let cardartitcle;
	let current;

	cardartitcle = new CardArtitcle({
			props: {
				title: /*card*/ ctx[2].name,
				description: /*card*/ ctx[2].description,
				image: /*card*/ ctx[2].imageSmall,
				link: /*card*/ ctx[2].link
			}
		});

	return {
		c() {
			create_component(cardartitcle.$$.fragment);
		},
		m(target, anchor) {
			mount_component(cardartitcle, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(cardartitcle.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(cardartitcle.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(cardartitcle, detaching);
		}
	};
}

function create_fragment(ctx) {
	let section;
	let section_class_value;
	let t;
	let div;
	let current;
	let each_value = dbCards;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			section = element("section");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			div = element("div");
			attr(section, "class", section_class_value = "" + (null_to_empty(`flex justify-center ${FluidContainer()} ${/*sectionTheme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] })}`) + " svelte-1y90u51"));
			attr(div, "class", "svelte-1y90u51");
		},
		m(target, anchor) {
			insert(target, section, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(section, null);
			}

			insert(target, t, anchor);
			insert(target, div, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*dbCards*/ 0) {
				each_value = dbCards;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(section, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty & /*$isDark*/ 1 && section_class_value !== (section_class_value = "" + (null_to_empty(`flex justify-center ${FluidContainer()} ${/*sectionTheme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] })}`) + " svelte-1y90u51"))) {
				attr(section, "class", section_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t);
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $isDark;
	component_subscribe($$self, isDark, $$value => $$invalidate(0, $isDark = $$value));

	const sectionTheme = css({
		variants: {
			isDark: {
				true: {
					backgroundColor: 'rgba(var(--dark-background), 0.8)'
				},
				false: {
					backgroundColor: 'rgba(var(--light-background), 0.4)'
				}
			}
		}
	});

	return [$isDark, sectionTheme];
}

class ListContainer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ListContainer;