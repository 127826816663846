/* ../src/components/ThemeManager/ThemeManager.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	attr,
	component_subscribe,
	create_slot,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	insert,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot_base
} from "svelte/internal";

import { css } from '@stitches/core';
import { getRandomNumber } from '../../utils/getRandomNumber';
import colors from '../../data/colors.json';
import { isDark } from '../../data/store';

function create_fragment(ctx) {
	let main;
	let main_class_value;
	let current;
	const default_slot_template = /*#slots*/ ctx[3].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], null);

	return {
		c() {
			main = element("main");
			if (default_slot) default_slot.c();
			attr(main, "class", main_class_value = `flex items-center flex-col ${/*theme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] })}`);
		},
		m(target, anchor) {
			insert(target, main, anchor);

			if (default_slot) {
				default_slot.m(main, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[2],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[2])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[2], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*$isDark*/ 1 && main_class_value !== (main_class_value = `flex items-center flex-col ${/*theme*/ ctx[1]({ isDark: /*$isDark*/ ctx[0] })}`)) {
				attr(main, "class", main_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $isDark;
	component_subscribe($$self, isDark, $$value => $$invalidate(0, $isDark = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	const random = getRandomNumber(0, colors.light.length);

	const theme = css({
		minHeight: '100vh',
		'--light-primary': `${colors.light[random]}`,
		'--dark-primary': `${colors.dark[random]}`,
		variants: {
			isDark: {
				true: { color: 'rgb(var(--dark-onSurface))' },
				false: { color: 'rgb(var(--light-onSurface))' }
			}
		}
	});

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(2, $$scope = $$props.$$scope);
	};

	return [$isDark, theme, $$scope, slots];
}

class ThemeManager extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ThemeManager;