/* ../src/components/CardArtitcle/CardArtitcle.svelte generated by Svelte v3.43.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	null_to_empty,
	safe_not_equal,
	set_data,
	space,
	src_url_equal,
	text
} from "svelte/internal";

import { Card, ButtonText } from '@makinox/makinox-ui';
import { isDark } from '../../data/store';

function create_fragment(ctx) {
	let article;
	let div0;
	let img;
	let img_src_value;
	let img_alt_value;
	let t0;
	let div1;
	let h6;
	let t1;
	let t2;
	let div2;
	let p;
	let t3;
	let t4;
	let div3;
	let button;
	let t5;
	let button_class_value;
	let article_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			article = element("article");
			div0 = element("div");
			img = element("img");
			t0 = space();
			div1 = element("div");
			h6 = element("h6");
			t1 = text(/*title*/ ctx[0]);
			t2 = space();
			div2 = element("div");
			p = element("p");
			t3 = text(/*description*/ ctx[2]);
			t4 = space();
			div3 = element("div");
			button = element("button");
			t5 = text("open");
			if (!src_url_equal(img.src, img_src_value = /*image*/ ctx[1])) attr(img, "src", img_src_value);
			attr(img, "alt", img_alt_value = `Image ${/*title*/ ctx[0]}`);
			attr(img, "class", "svelte-lfe9ka");
			attr(div0, "class", "card-media");
			attr(h6, "class", "headline6");
			attr(div1, "class", "card-header");
			attr(p, "class", "body2 svelte-lfe9ka");
			attr(div2, "class", "card-body");
			attr(button, "class", button_class_value = ButtonText({ isDark: /*$isDark*/ ctx[4] }));
			attr(div3, "class", "card-bottom");

			attr(article, "class", article_class_value = "" + (null_to_empty(Card({
				isDark: /*$isDark*/ ctx[4],
				css: { maxWidth: '290px', width: '100%' }
			})) + " svelte-lfe9ka"));
		},
		m(target, anchor) {
			insert(target, article, anchor);
			append(article, div0);
			append(div0, img);
			append(article, t0);
			append(article, div1);
			append(div1, h6);
			append(h6, t1);
			append(article, t2);
			append(article, div2);
			append(div2, p);
			append(p, t3);
			append(article, t4);
			append(article, div3);
			append(div3, button);
			append(button, t5);

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[5]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*image*/ 2 && !src_url_equal(img.src, img_src_value = /*image*/ ctx[1])) {
				attr(img, "src", img_src_value);
			}

			if (dirty & /*title*/ 1 && img_alt_value !== (img_alt_value = `Image ${/*title*/ ctx[0]}`)) {
				attr(img, "alt", img_alt_value);
			}

			if (dirty & /*title*/ 1) set_data(t1, /*title*/ ctx[0]);
			if (dirty & /*description*/ 4) set_data(t3, /*description*/ ctx[2]);

			if (dirty & /*$isDark*/ 16 && button_class_value !== (button_class_value = ButtonText({ isDark: /*$isDark*/ ctx[4] }))) {
				attr(button, "class", button_class_value);
			}

			if (dirty & /*$isDark*/ 16 && article_class_value !== (article_class_value = "" + (null_to_empty(Card({
				isDark: /*$isDark*/ ctx[4],
				css: { maxWidth: '290px', width: '100%' }
			})) + " svelte-lfe9ka"))) {
				attr(article, "class", article_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(article);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $isDark;
	component_subscribe($$self, isDark, $$value => $$invalidate(4, $isDark = $$value));
	let { title = '' } = $$props;
	let { image = '' } = $$props;
	let { description = '' } = $$props;
	let { link = '' } = $$props;
	const click_handler = () => window.open(link, '_blank');

	$$self.$$set = $$props => {
		if ('title' in $$props) $$invalidate(0, title = $$props.title);
		if ('image' in $$props) $$invalidate(1, image = $$props.image);
		if ('description' in $$props) $$invalidate(2, description = $$props.description);
		if ('link' in $$props) $$invalidate(3, link = $$props.link);
	};

	return [title, image, description, link, $isDark, click_handler];
}

class CardArtitcle extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			title: 0,
			image: 1,
			description: 2,
			link: 3
		});
	}
}

export default CardArtitcle;